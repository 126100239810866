import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import { Toast } from 'rad_common_js/src/toast';

export default class extends Controller {
  static values = { urlTemplate: String, targetSelector: String };

  connect() {
    if (this.hasTargetSelectorValue) {
      this.targetElement = document.querySelector(this.targetSelectorValue);
    }
  }

  async onChange() {
    if (!this.targetElement) {
      return;
    }
    this.clearTarget();
    const url = this.constructUrl(this.element.value);
    if (url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          Toast.error('An error occurred while fetching data.');
          return;
        }

        const data = await response.json();
        this.populateTarget(data);
        this.refreshSelectPicker();
        this.reinitializeTarget();
      } catch {
        Toast.error('An error occurred while fetching data.');
      }
    } else {
      this.reinitializeTarget();
    }
  }

  clearTarget() {
    this.targetElement.innerHTML = '<option></option>';
  }

  populateTarget(data) {
    data.forEach((item) => {
      const option = document.createElement('option');
      option.value = item.id;
      option.textContent = item.name;
      this.targetElement.appendChild(option);
    });
  }

  refreshSelectPicker() {
    $(this.targetElement).selectpicker('refresh');
  }

  reinitializeTarget() {
    this.targetElement.dispatchEvent(new Event('change'));
  }

  constructUrl(id) {
    if (id && id !== '') {
      return this.urlTemplateValue.replace(/:\w+_id/, id).replace(/%3A\w+_id/, id);
    }
    return null;
  }
}
